import { template as template_4083239267fe4937bf323d2d0561cf54 } from "@ember/template-compiler";
const EmptyState = template_4083239267fe4937bf323d2d0561cf54(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
