import { template as template_d79edf9f40a145edb3a1ef58f113480b } from "@ember/template-compiler";
const WelcomeHeader = template_d79edf9f40a145edb3a1ef58f113480b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
