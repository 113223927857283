import { template as template_53322e5fecfe4a5eb1801e6176f02265 } from "@ember/template-compiler";
const SidebarSectionMessage = template_53322e5fecfe4a5eb1801e6176f02265(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
